<template>
  <div>
    <div class="row">
      <div class="col-md-12 pt-2 flex-column d-flex align-items-start">
        <h3 class="color-text f-600">Configurações</h3>
        <hr class="divisor">
      </div>
      <div class="col-md-12">
                        <form v-on:submit.prevent="">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="Galaxpay" class="fs-12 color-text"
                      >Token Galaxpay</label
                    >
                    <InputText
                      id="Galaxpay"
                      class="form-input"
                      type="text"
                      v-model="galax"
                      required
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="Wordpress" class="fs-12 color-text"
                      >Token Wordpress</label
                    >
                    <InputText
                      id="Wordpress"
                      class="form-input"
                      type="text"
                      v-model="wordpress"
                      disabled
                      required
                    />
                  </div>
                                    <div class="form-group col-md-12">
                    <button
                      type="submit"
                      class="btn btn-success ml-auto flex-row d-flex justify-content-center align-items-center"
                      style="width: 250px"
                    >
                      <div
                        class="spinner-grow text-light"
                        role="status"
                        v-if="loading"
                      ></div>
                      <span class="fs-14 text-uppercase">Salvar</span>
                    </button>
                  </div>
                </div>
              </form>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Configs",
        data(){
            return{
                loading: false,
                galax: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIj",
                wordpress: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIj"
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>