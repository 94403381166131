<template>
  <div>
    <div class="row mt-4 mt-md-0">
      <div class="col-md-6 d-flex align-items-center">
        <h3 class="color-text f-600">Usuários Administradores</h3>
      </div>
      <div class="col-md-6 d-md-flex justify-content-end">
        <div class="position-relative as-icon">
          <InputText
            id="search"
            class="form-input"
            placeholder="Pesquisar..."
            type="text"
            required
          />
          <feather class="color-text is-18" type="search"></feather>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12" v-if="loading">
        <content-placeholders rounded>
          <content-placeholders-img />
          <content-placeholders-text :lines="4" />
        </content-placeholders>
      </div>

      <div class="col-12" v-if="!loading">
        <DataTable
          :paginator="true"
          :rows="10"
          dataKey="id"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          class="p-datatable-responsive-demo"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}"
          selectionMode="single"
          :value="pedidos"
        >
          <Column field="cliente" header="CLiente"></Column>
          <Column field="data" header="Data"></Column>
          <Column field="nome" header="Treinamento"></Column>
          <Column field="inscritos" header="Total inscritos"></Column>
          <Column field="status" header="Status"></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "Administradores",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      users: [],
      loading: false,
    };
  },
  methods: {
    getUsers() {
      this.loading = true;
      this.$http.get("/users").then((response) => {
        this.loading = false;
        this.users = response.data;
      });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
</style>